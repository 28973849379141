import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, ListGroup, ListGroupItem, Row, Col, Table, Button, Modal } from 'react-bootstrap';
import { fetchReservationById } from '../../../actions/request/ReservationRequest';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import { MdDownload } from 'react-icons/md';
import { calculateDuration, formatDateView } from '../../../utils/functionData/dateFunction';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { logoApplication } from '../../../utils/dataApi/dataFormApi';
import { FaCreditCard, FaMoneyBillWave, FaUniversity } from 'react-icons/fa';

const ReservationInvoicePage = () => {
    const reservationParam = useParams();
    const dispatch = useDispatch();
    const reservation = useSelector((state) => state.reservations.reservation);
    const invoiceRef = useRef(); // Ref to target the section to export

    const [idReservation, setidReservation] = useState();

    useEffect(() => {
        setidReservation(reservationParam?.id)
        dispatch(fetchReservationById(reservationParam?.id || idReservation));
    }, [dispatch, reservationParam?.id]);

    const [showModalPdfConfirm, setshowModalPdfConfirm] = useState(false);




    if (!reservation) {
        return <p>Aucune Facture trouvée sur cette reservation.</p>;
    }

    const {
        vehicle,
        property,
        client,
        firstname, lastname, email, codePostal, phone,
        startDate,
        endDate,
        totalPrice,
        deliveryFee,
        discount,
        commissionPercentage,
        numberOfGuests,
        specialRequests,
    } = reservation;


    const calculeDiscount = () => {
        const remiseDiscount = Number(Number(totalPrice || 0) - Number(discount || 0))
        return remiseDiscount
    }

    // Calcul des jours de location
    const start = new Date(startDate);
    const end = new Date(endDate);
    const numberOfDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));



    const duration = calculateDuration(startDate, endDate);
    const commissionAmount = (deliveryFee * (commissionPercentage / 100)).toFixed(2);

    const getPaymentMethodLabel = (paymentMethod) => {
        switch (paymentMethod) {
            case "cash":
                return "Espèces";
            case "bank":
                return "Virement bancaire";
            case "card":
                return "Carte de crédit";
            default:
                return "Non spécifié";
        }
    };


    const handleDownloadPDF = () => {
        const doc = new jsPDF();

        // Ajout du logo
        doc.addImage(logoApplication, 'PNG', 10, 10, 40, 15);

        // Titre centré
        doc.setFontSize(16);
        doc.text('Facture de Réservation', doc.internal.pageSize.width / 2, 30, { align: 'center' });

        doc.setFontSize(10);
        const leftX = 10;
        const rightX = doc.internal.pageSize.width - 80;
        let y = 50;

        // Informations du client et de la réservation
        doc.text(`Client: ${firstname || ''} ${lastname || ''}`, leftX, y);
        doc.text(`Email: ${email || ''}`, leftX, y + 10);
        doc.text(`Téléphone: +${codePostal.indicatif || ''} ${phone || ''}`, leftX, y + 20);

        doc.text(`Réservation: ${reservation.ordre || ''}`, rightX, y);
        doc.text(`Date de début: ${formatDateView(startDate)} - ${moment(startDate).format("HH:MM")}`, rightX, y + 10);
        doc.text(`Date de fin: ${formatDateView(endDate)} - ${moment(endDate).format("HH:MM")}`, rightX, y + 20);

        y += 40;

        // Tableau des détails du véhicule ou propriété
        const details = [];
        if (vehicle) {
            details.push(['Nom', vehicle?.brand || '']);
            details.push(['Marque', vehicle?.vehiclebrand || '']);
            details.push(['Modèle', vehicle?.model || '']);
            details.push(['Couleur', vehicle?.color || '']);
        } else if (property) {
            details.push(['Nom', property?.name || '']);
            details.push(['Adresse', property?.address || '']);
            details.push(['Année', property?.yearBuilt || '']);
        }


        // Ajout du moyen de paiement
        if (reservation && reservation.paymentMethod) {
            details.push(['Moyen de paiement', getPaymentMethodLabel(reservation.paymentMethod)]);
        }

        // Ajout des informations bancaires si le paiement est par virement bancaire
        if (reservation && reservation.paymentMethod === "bank" && reservation.userAdd?.bankInfos) {
            reservation.userAdd.bankInfos.forEach((item) => {
                if (item.bankAccountNumber) details.push(['Numéro de compte Agence', item.bankAccountNumber]);
                if (item.bankName) details.push(["Banque de l'agence", item.bankName]);
                if (item.iban) details.push(["IBAN de l'agence", item.iban]);
                if (item.swiftCode) details.push(["Code SWIFT/BIC de l'agence", item.swiftCode]);
            });
        }


        if (details.length > 0) {
            doc.autoTable({
                startY: y,
                head: [['Détails', 'Informations']],
                body: details,
                theme: 'grid',
            });
            y = doc.autoTable.previous.finalY + 10;
        }

        // Tableau des coûts et paiements
        const payments = [
            ['Prix', Number(vehicle?.basePricePerDay * numberOfDays || property?.basePricePerDay * numberOfDays || 0).toFixed(0) + " DZD"],
        ];

        if (reservation?.deliveryFee > 0) {
            payments.push(['Frais supplémentaires', `${reservation.deliveryFee} DZD`]);
        }

        if (commissionAmount && commissionAmount > 0) {
            payments.push(['Accompte', `${Number(commissionAmount).toFixed(0)} DZD`]);
        }

        if (reservation?.paymentCard?.card?.amount > 0) {
            payments.push(['Accompte de réservation', "-" + Number(reservation?.paymentCard?.card?.amountReal * 0.1).toFixed(0) + " DZD"]);
        }

        if (reservation?.discount && reservation.discount > 0) {
            payments.push(['Remise', `-${Number(reservation.discount).toFixed(0)} DZD`]);
        }

        payments.push(['Total', `${Number(totalPrice || 0).toFixed(0)} DZD`]);

        doc.autoTable({
            startY: y,
            head: [['Informations', 'Montant']],
            body: payments,
            theme: 'grid',
            styles: { fontSize: 11, cellPadding: 3 },
        });


        // Pied de page
        doc.setFontSize(8);
        doc.text('Merci pour votre confiance.', 10, doc.internal.pageSize.height - 10);

        // Lien vers le site web
        doc.setTextColor(46, 128, 2);
        doc.textWithLink('Visitez notre site : www.rezaplus.com', 10, doc.internal.pageSize.height - 5, { url: 'https://www.rezaplus.com' });


        doc.save(`Facture_Reservation_${reservation.ordre || ''}.pdf`);
    };


    const handleShowPdfConfirm = () => { setshowModalPdfConfirm(true) }
    const handleClosePdfConfirm = () => { setshowModalPdfConfirm(false) }




    return (
        <div className="">
            <Card ref={invoiceRef}>
                <Card.Header>
                    <div style={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        <div><h3>Facture de réservation {reservation?.ordre || ""}</h3></div>
                        <div>
                            <Button id="downloadButton" onClick={handleShowPdfConfirm} variant="primary" size='sm'>
                                <MdDownload />
                                <span className="text-sm"> Télécharger </span>
                            </Button>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body >
                    <Row>
                        {
                            vehicle && (
                                <Col md={6}>
                                    <h4>Détails du véhicule</h4>
                                    <ListGroup>
                                        <ListGroupItem><strong>Nom / designation:</strong> {vehicle?.brand || ""}</ListGroupItem>
                                        <ListGroupItem><strong>Marque:</strong> {vehicle?.vehiclebrand || ""}</ListGroupItem>
                                        <ListGroupItem><strong>Modèle:</strong> {vehicle?.model || ""}</ListGroupItem>
                                        <ListGroupItem><strong>Coleur:</strong> {vehicle?.color || ""}</ListGroupItem>
                                    </ListGroup>
                                </Col>
                            )
                        }

                        {
                            property &&
                            (
                                <Col md={6}>
                                    <h4>Détails de la propriété</h4>
                                    <ListGroup>
                                        <ListGroupItem><strong>Nom/Designation :</strong> {property?.name || ""}</ListGroupItem>
                                        <ListGroupItem><strong>Addresse : </strong> {property?.address || ""}</ListGroupItem>
                                        <ListGroupItem><strong>Année:</strong> {property?.yearBuilt || ""}</ListGroupItem>
                                    </ListGroup>
                                </Col>
                            )
                        }


                        <Col md={6}>
                            <h4>Détails du client</h4>
                            <ListGroup>
                                <ListGroupItem><strong>Nom:</strong> {firstname ? firstname : ""} {lastname ? lastname : ""}</ListGroupItem>
                                <ListGroupItem><strong>Email:</strong> {email ? email : ""}</ListGroupItem>
                                <ListGroupItem><strong>Téléphone:</strong> +{codePostal?.indicatif || ""} {phone ? phone : ""}</ListGroupItem>
                                {
                                    reservation?.companyAirport && reservation?.companyAirport?.name &&
                                    <ListGroupItem>
                                        <strong>Compagnie aérienne :</strong>
                                        <span>{reservation?.companyAirport?.name || ""}</span>
                                    </ListGroupItem>
                                }
                                {
                                    reservation?.numberVolAiport &&
                                    <ListGroupItem>
                                        <strong>N° de Vol :</strong>
                                        <span>{reservation?.numberVolAiport}</span>
                                    </ListGroupItem>
                                }
                            </ListGroup>
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col>
                            <h4>Détails de la réservation</h4>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Date de début</th>
                                        <th>Date de fin</th>
                                        <th>Nombre de jours</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{formatDateView(start || "")} - {moment(startDate).format("HH:mm") || ""}</td>
                                        <td>{formatDateView(end || "")} - {moment(endDate).format("HH:mm") || ""}</td>
                                        <td>{duration?.days || ""} jour{duration.days > 1 ? 's' : ''}
                                            {/*, {duration.hours} heure{duration.hours > 1 ? 's' : ''}, {duration.minutes} minute{duration.minutes > 1 ? 's' : ''} */}
                                        </td>
                                    </tr>
                                </tbody>

                                {

                                }
                            </Table>

                            {/*<h5>Demande spéciale :</h5>
                            <p>{specialRequests || "Aucune demande spéciale."}</p> */}
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col>
                            <h4>Détails du paiement</h4>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Informations</th>
                                        <th>Montant (DZD)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reservation && reservation.paymentMethod ?
                                            <tr>
                                                <td>Moyen de paiement </td>
                                                <td> <span className=""> <span className={`ms-2 status`}>
                                                    {' '}
                                                    {/* Adds left margin between label and status */}
                                                    {reservation.paymentMethod === "cash" && (<div className="d-flex flex flex-row"><FaMoneyBillWave size={24} /> <span> Espèces </span> </div>)}
                                                    {reservation.paymentMethod === "bank" && (<div className="d-flex flex flex-row"><FaUniversity size={24} /> <span> Virement bancaire </span> </div>)}
                                                    {reservation.paymentMethod === "card" && (<div className="d-flex flex flex-row"><FaCreditCard size={24} /> <span> Carte de crédit </span> </div>)}
                                                </span></span> </td>
                                            </tr> :
                                            ""
                                    }
                                    {
                                        reservation && reservation.paymentMethod == "bank" ?
                                            <tr>
                                                <td>Information du paiement </td>
                                                <td> <span className=""> <span className={`ms-2 `}>
                                                    {' '}
                                                    {/* Adds left margin between label and status */}
                                                    {
                                                        reservation?.userAdd?.bankInfos.map((item) => {
                                                            return (<div className="py-2 border-bottom">
                                                                {item?.bankAccountNumber && (<div className="d-flex flex flex-row">Numero de compte : <span> {item?.bankAccountNumber || ""} </span> </div>)}
                                                                {item?.bankName && (<div className="d-flex flex flex-row">Banque : <span> {item?.bankName || ""} </span> </div>)}
                                                                {item?.iban && (<div className="d-flex flex flex-row">IBAN : <span> {item?.iban || ""} </span> </div>)}
                                                                {item?.swiftCode && (<div className="d-flex flex flex-row">code swift : <span> {item?.swiftCode || ""} </span> </div>)}
                                                            </div>
                                                            )
                                                        })
                                                    }
                                                </span></span> </td>
                                            </tr> :
                                            ""
                                    }
                                    {
                                        vehicle && (
                                            <tr>
                                                <td>Prix ({vehicle?.basePricePerDay} / jour)</td>
                                                <td>{(vehicle?.basePricePerDay * numberOfDays).toFixed(0)} </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        property && (
                                            <tr>
                                                <td>Prix ( {property?.basePricePerDay}/Jour ) </td>
                                                <td>{(property?.basePricePerDay * numberOfDays).toFixed(0)} </td>
                                            </tr>
                                        )
                                    }

                                    {
                                        reservation && reservation.deliveryFee && Number(reservation.deliveryFee) > 0 ?
                                            <tr>
                                                <td>Frais Supplementaires</td>
                                                <td>{reservation && reservation.deliveryFee ? reservation.deliveryFee : "0"} </td>
                                            </tr>
                                            : ""
                                    }



                                    {
                                        commissionAmount && commissionAmount != 0 && (
                                            <tr>
                                                <td>Accompte </td>
                                                <td>{Number(commissionAmount).toFixed(0) || 0} </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        reservation && reservation.paymentCard && reservation.paymentCard && reservation?.paymentCard.card && reservation?.paymentCard.card.amount !== 0
                                            ?
                                            <tr>
                                                <td>Accompte de réservation </td>
                                                <td> <span className="text-danger"> - {Number(reservation?.paymentCard.card.amountReal * 0.1)}  </span> </td>
                                            </tr> :
                                            ""
                                    }
                                    {
                                        reservation && reservation.discount && reservation.discount !== 0 ?
                                            <tr>
                                                <td>Remise </td>
                                                <td>{Number(reservation.discount).toFixed(0)} </td>
                                            </tr> :
                                            ""
                                    }

                                    <tr>
                                        <td><strong>Total</strong></td>
                                        <td><strong style={{ fontWeight: "bold" }}>{Number(totalPrice || 0).toFixed(0)} </strong></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>


            <Modal show={showModalPdfConfirm} onHide={handleClosePdfConfirm} centered >
                <Modal.Header>Confirmer téléchargement</Modal.Header>
                <Modal.Body>
                    <p>Voulez télécharger la facture de la réservation</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='light' size='sm' onClick={handleClosePdfConfirm}> Annuler </Button>
                    <Button variant='primary' size='sm' onClick={handleDownloadPDF}><MdDownload /> <span>Télécharger</span> </Button>
                </Modal.Footer>

            </Modal>


        </div>
    );
};

export default ReservationInvoicePage;
