import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Table, Dropdown, Image, Col, Row, Card, Badge } from 'react-bootstrap'; // Ensure react-bootstrap is installed
import Select from 'react-select'; // Ensure react-select is installed
import "react-datepicker/dist/react-datepicker.css";
import { FaUsers } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UserUpdateById, UserUpdateStatut, fetchUsersAll } from '../../../actions/request/UserRequest';
import { emptyImage } from '../../../utils/dataApi/dataFormApi';
import { toast } from 'react-toastify';
import { AccessDeleteAccompteyId } from '../../../actions/request/AccessRequest';
import { FETCH_CUSTOMER_SUCCESS, FETCH_USER_SUCCESS } from '../../../app/actions/actions';

const UserManagementPage = () => {

    const [formData, setFormData] = useState({
        activateReservation: false
    })
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const usersData = useSelector((state) => state.users.users);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(null)

    useEffect(() => {
        dispatch({ type: FETCH_USER_SUCCESS });
                dispatch({ type: FETCH_CUSTOMER_SUCCESS });
        dispatch(fetchUsersAll());
    }, [dispatch]);


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleStatusChange = (selectedOption) => {
        setSelectedStatus(selectedOption);
    };


    const renderStatusBadge = (status) => {
        switch (status) {
            case 'Active':
                return <Badge bg="success">Actif</Badge>;
            case 'Blocked':
                return <Badge bg="danger">Bloqué</Badge>;
            default:
                return <Badge bg="secondary">{status}</Badge>;
        }
    };

    const [userItem, setuserItem] = useState(null);
    const [actionModal, setactionModal] = useState("")
    const [showModal, setshowModal] = useState(false);
    const [showModalDelete, setshowModalDelete] = useState(false);
    const handleShowModal = (action, objet) => {
        setshowModal(true);
        setactionModal(action)
        setuserItem(objet);
    }
    const handleShowModalDelete = (action, objet) => {
        setshowModalDelete(true);
        setactionModal(action)
        setuserItem(objet);
    }

    const handleCloseModal = () => { setshowModal(false); }

    const handleSubmit = () => {
        if (actionModal == "unclock") {
            dispatch(UserUpdateStatut(userItem?._id, toast))
            handleCloseModal();
        }
        else if (actionModal == "activated") {
            dispatch(UserUpdateStatut(userItem?._id, toast))
            handleCloseModal();
        }
        else if (actionModal == "unActiveAccompte") {
            dispatch(UserUpdateById(userItem?._id, formData, toast))
            handleCloseModal();
        }
        else if (actionModal == "ActiveAccompte") {
            dispatch(UserUpdateById(userItem?._id, formData, toast))
            handleCloseModal();
        }
        else if (actionModal == "delete") {
            dispatch(AccessDeleteAccompteyId(userItem?._id, toast))
            handleCloseModal();
        }
    }




    const [isActiveAccountReservation, setisActiveAccountReservation] = useState(false)
    const handleSubmitActiveAccountReservation = (e) => {
        e.preventDefault();
        // Submit logic here
    };





    return (
        <div className="container-fluid p-3 " style={{ marginBottom: "200px" }}>


            <Card>
                <Card.Header>
                    <h3> <FaUsers /> Utilisateurs </h3>
                </Card.Header>
                <Card.Body style={{ minHeight: "500px" }}>
                    <Row>
                        {/*<Col md={3}>
                            <Form.Group controlId="search">
                                <Form.Control
                                    type="text"
                                    placeholder="Recherche"
                                    value={searchTerm}
                                    style={{ height: "40px" }}
                                    onChange={handleSearchChange}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={3}>
                            <Form.Group controlId="status">
                                <Select
                                    options={[
                                        { value: 'Active', label: 'Actif' },
                                        { value: 'Blocked', label: 'Bloqué' }
                                    ]}
                                    onChange={handleStatusChange}
                                    isClearable
                                    placeholder="Statut"
                                />
                            </Form.Group>
                        </Col> */}
                    </Row>

                    <Table striped bordered hover className="mt-5" responsive>
                        <thead>
                            <tr>
                                <th>Photo</th>
                                <th>Type</th>
                                <th>Nom de l{"'"}agence</th>
                                <th>Nom & Prénoms</th>
                                <th>Email</th>
                                <th>Téléphone</th>
                                <th>Accompte (13%)</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usersData.map((user) => (
                                <tr key={user.id}>
                                    <td>
                                        {
                                            user.profilePicture ?
                                                <Image src={user.profilePicture} roundedCircle style={{ width: '50px', height: '50px' }} />
                                                :
                                                <Image src={emptyImage} roundedCircle style={{ width: '50px', height: '50px' }} />
                                        }
                                    </td>
                                    <td>
                                        {
                                            user && user.role && user.role == "COMPANY" ?
                                                "Entreprise" :
                                                "Particulier"
                                        }
                                    </td>
                                    <td>{user?.companyName || ""}</td>
                                    <td>{user.firstname || ""} {user.lastname || ""}</td>
                                    <td>{user.email}</td>
                                    <td> {`+${user.codePostal.indicatif}`}  {user.phone}</td>
                                    <td>
                                        {
                                            user && user?.activateReservation && user?.activateReservation == true ?
                                                <span className='text-success'>Oui</span> : <span className='text-danger'>Non</span>
                                        }
                                    </td>
                                    <td>{renderStatusBadge(user.status)}</td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle size='sm' variant="light">
                                                Action
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {
                                                    user && user?.activateReservation && user?.activateReservation == true ?
                                                        <Dropdown.Item href="#unclock" onClick={() => { handleShowModal("unActiveAccompte", user); setFormData({ activateReservation: false }) }}>Bloquer accompte de réservation</Dropdown.Item>
                                                        :
                                                        <Dropdown.Item href="#activeAccountReservation" onClick={() => { handleShowModal("ActiveAccompte", user); setFormData({ activateReservation: true }) }}>Activer accompte  de réservation</Dropdown.Item>
                                                }

                                                {
                                                    user?.status && user?.status == "Active" ?
                                                        <Dropdown.Item href="#unclock" onClick={() => { handleShowModal("unclock", user) }}>Bloquer compte</Dropdown.Item>
                                                        :
                                                        <Dropdown.Item href="#unclock" onClick={() => { handleShowModal("unclock", user) }}>Activer compte</Dropdown.Item>
                                                }
                                                {
                                                    user?.access && (
                                                        <Dropdown.Item href="#delete" onClick={() => { handleShowModal("delete", user) }}>Supprimer compte</Dropdown.Item>
                                                    )
                                                }



                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>


            <Modal show={showModal} onHide={handleCloseModal} size='md' centered>
                <Modal.Header>
                    <h5>
                        Confirmer votre action
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex justify-content-center text-center' style={{ minHeight: "70px", width: "100%" }}>
                        <div>
                            <p>Êtes vous sur de vouloir <span className="font-weight-700">
                                {actionModal == "unclock" ? <span>{userItem?.status=="Active" ? "Bloquer ce compte ":" Activer ce compte " + userItem?.firstname + " " + userItem?.lastname} </span> : ""}
                                {actionModal == "activated" ? <span>{"Activer ce compte " + userItem?.firstname + " " + userItem?.lastname} </span> : ""}
                                {actionModal == "unActiveAccompte" ? <span>{"Activer l'accompte de la réservation pour cet compte " + userItem?.firstname + " " + userItem?.lastname} </span> : ""}
                                {actionModal == "ActiveAccompte" ? <span>{"Désactiver l'accompte de la réservation pour cet compte " + userItem?.firstname + " " + userItem?.lastname} </span> : ""}
                                {actionModal == "delete" && (<span>{"Supprimer cet compte " + userItem?.firstname + " " + userItem?.lastname} </span>)}
                            </span></p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button type="button" size="sm" variant="light" onClick={handleCloseModal}>Fermer</Button>

                    {
                        actionModal == "unclock" ?
                            <Button type="button" size="sm" variant="danger" onClick={handleSubmit}>Confirmer</Button>
                            :
                            actionModal == "activated" ?
                                <Button type="button" size="sm" variant="success" onClick={handleSubmit}>Confirmer</Button>
                                : ""
                    }

                    {
                        actionModal == "unActiveAccompte" ?
                            <Button type="button" size="sm" variant="danger" onClick={handleSubmit}>Confirmer</Button>
                            :
                            actionModal == "ActiveAccompte" ?
                                <Button type="button" size="sm" variant="success" onClick={handleSubmit}>Confirmer</Button>
                                : ""
                    }


                    {
                        actionModal == "delete" && (
                            <Button type="button" size="sm" variant="danger" onClick={handleSubmit}>confirmer</Button>
                        )
                    }

                </Modal.Footer>
            </Modal>




        </div>
    );
};

export default UserManagementPage;
