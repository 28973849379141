import React from "react";
import { FaMoneyBillWave, FaCreditCard, FaExclamationCircle, FaUniversity } from "react-icons/fa"; // Importez les icônes nécessaires

const PaymentReservationConfirmationMessage = ({ paymentMethodR }) => {
  return (
    <div className="card shadow-sm p-3 mb-4" style={{ maxWidth: "500px", margin: "auto" }}>
      <div className="d-flex align-items-center gap-3">
        {/* Icône */}
        <div>
          {paymentMethodR === "cash" && <FaMoneyBillWave size={24}  />}
          {paymentMethodR === "bank" && <FaUniversity size={24}  />}
          {paymentMethodR === "card" && <FaCreditCard size={24}  />}
          {!["cash", "bank", "card"].includes(paymentMethodR) && <FaExclamationCircle size={24} color="#dc3545" />}
        </div>

        {/* Message */}
        <div>
          {paymentMethodR === "cash" && (
            <p className="mb-0 text-success text-center">Règlement de la réservation en espèces sera effectué auprès de l{"'"}agence.</p>
          )}
          {paymentMethodR === "bank" && (
            <p className="mb-0 text-success text-center">Règlement de la réservation par virement bancaire sera effectué auprès de l{"'"}agence.</p>
          )}
          {paymentMethodR === "card" && (
            <p className="mb-0 text-success text-center">Règlement de la réservation par carte de crédit sera effectué auprès de l{"'"}agence.</p>
          )}
          {!["cash", "bank", "card"].includes(paymentMethodR) && (
            <p className="mb-0 text-danger">Mode de paiement non spécifié.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentReservationConfirmationMessage;