import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Badge, Modal, Form, Card, Spinner, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReservationsAll, fetchReservationsAllByidClientId, fetchReservationsAllByidUserId } from '../../../actions/request/ReservationRequest';
import { MdDeleteOutline, MdSearch } from 'react-icons/md';
import moment from 'moment/moment';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { emptyImage, profileRoleType } from '../../../utils/dataApi/dataFormApi';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { ROUTES } from '../../../config/routingUrl';
import { FETCH_PROPERTY_SUCCESS, FETCH_VEHICLE_SUCCESS } from '../../../app/actions/actions';
import { fetchPropertysAllByOwnerId, fetchPropertysFilters } from '../../../actions/request/PropertyRequest';
import { fetchVehiclesAllByOwnerId, fetchVehiclesAllFilter } from '../../../actions/request/VehicleRequest';
import { exportToExcelDataReservation, exportToPDFDataReservation } from '../../../utils/functionData/ExportFileDocumentReservation';
import { formatDateView } from '../../../utils/functionData/dateFunction';
import { FaCreditCard, FaMoneyBillWave, FaUniversity } from 'react-icons/fa';

const RequestReservationPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const propertys = useSelector((state) => state.propertys.propertys);
    const [reservationData, setreservationData] = useState([])
    const reservations = useSelector((state) => state.reservations.reservations);

    const [filteredData, setFilteredData] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);

    const itemsPerPage = 50;

    const callFunctionElements = () => {

        setSelectedVehicle("");
        setSelectedProperty("");
        setStartDate("");
        setEndDate("");

        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {

            dispatch(fetchVehiclesAllByOwnerId(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchPropertysAllByOwnerId(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchReservationsAllByidUserId(getAndCheckLocalStorage(localStorageKeys.userId)));
        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
            dispatch(fetchReservationsAllByidClientId(getAndCheckLocalStorage(localStorageKeys.userId)));
        }
        else {
            dispatch(fetchPropertysFilters());
            dispatch(fetchVehiclesAllFilter());
            dispatch(fetchReservationsAll());
        }

    }

    useEffect(() => {
        callFunctionElements();
    }, [dispatch]);

    useEffect(() => {

        if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
            const reservationGetData = reservations && reservations.length > 0 ? [...reservations].filter((reserv) => (reserv.vehicle && reserv.vehicle.addBy && reserv.vehicle.addBy == getAndCheckLocalStorage(localStorageKeys.userId)) || (reserv.property && reserv.property.addBy && reserv.property.addBy == getAndCheckLocalStorage(localStorageKeys.userId))) : []
            setreservationData(reservationGetData);
            setFilteredData(reservationGetData)

        } else {
            setreservationData(reservations)
            setFilteredData(reservations)
        }

    }, [reservations]);

    const statusOptions = [
        { value: 'PENDING', label: 'En attente' },
        { value: 'CONFIRMED', label: 'Confirmée' },
        { value: 'CANCELLED', label: 'Annulée' },
        { value: 'COMPLETED', label: 'Terminée' },
    ];

    const vehicleOptions = vehicles && vehicles.length > 0 ? vehicles.map((item) => {
        return ({
            value: item._id, label: item.brand
        })
    }) : [];

    const propertyOptions = propertys && propertys.length > 0 ? propertys.map((item) => {
        return ({
            value: item._id, label: item.name
        })
    }) : [];

    const renderStatusBadge = (status) => {
        switch (status) {
            case 'PENDING':
                return <Badge bg="warning">En attente</Badge>;
            case 'CONFIRMED':
                return <Badge bg="success">Confirmée</Badge>;
            case 'CANCELLED':
                return <Badge bg="danger">Annulée</Badge>;
            case 'COMPLETED':
                return <Badge bg="primary">Terminée</Badge>;
            default:
                return <Badge bg="secondary">{status}</Badge>;
        }
    };

    // Pagination logic
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedData = filteredData.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };



    const renderPagination = () => {
        const pageNumbers = [];
        const maxButtons = 5;
        const start = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
        const end = Math.min(start + maxButtons - 1, totalPages);

        for (let i = start; i <= end; i++) {
            pageNumbers.push(
                <Button size='sm'
                    key={i}
                    variant={i === currentPage ? 'primary' : 'outline-primary'}
                    onClick={() => handlePageChange(i)}
                    className="mx-1"
                >
                    {i}
                </Button>
            );
        }

        return (
            <div className="d-flex justify-content-start mt-3">
                <Button size="sm" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
                    Précédent
                </Button>
                {pageNumbers}
                <Button size="sm" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
                    Suivant
                </Button>
            </div>
        );
    };




    const [showLoading, setshowLoading] = useState(false);
    const handleShowLoading = () => { setshowLoading(true) }
    const handleCloseLoading = () => { setshowLoading(false) }







    // function filter
    const handleSearchFilter = () => {
        handleShowLoading();
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
            // administrateur de la plateforme en question
            dispatch(fetchReservationsAllByidUserId(
                getAndCheckLocalStorage(localStorageKeys.userId),
                startDate, endDate, selectedProperty?.value, selectedVehicle?.value, ""
            ))


        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
            dispatch(fetchReservationsAllByidClientId(
                getAndCheckLocalStorage(localStorageKeys.userId),
                startDate, endDate, selectedProperty?.value, selectedVehicle?.value
            ));
        }
        else {
            dispatch(fetchReservationsAll(startDate, endDate, selectedProperty?.value, selectedVehicle?.value, ""));

        }
        handleCloseLoading();
    }



    return (
        <div className="" style={{ marginBottom: "200px" }}>
            <Card>
                <Card.Header style={{ width: "100%", flexWrap: "wrap", justifyContent: "space-between" }}>

                    <div>
                        <h3>Réservations</h3>
                    </div>


                    <div>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" className='btn btn-sm' id="dropdown-basic">
                                Exporter
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => { exportToExcelDataReservation(reservations, "Listes_des_réservations") }}>Exporter en Excel</Dropdown.Item>
                                <Dropdown.Item onClick={() => { exportToPDFDataReservation(reservations, "Listes_des_réservations") }}>Export en Pdf</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                </Card.Header>
                <Card.Body>
                    {/* Filtres */}
                    <Row className="mb-3">
                        {
                            (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                            ) &&
                            vehicleOptions && vehicleOptions.length > 0 && (
                                <Col md={2}>
                                    <Select
                                        value={selectedVehicle}
                                        onChange={setSelectedVehicle}
                                        isClearable
                                        options={vehicleOptions}
                                        placeholder="Véhicule"
                                        isDisabled={!!selectedProperty} // Désactiver si une propriété est sélectionnée
                                    />
                                </Col>
                            )
                        }


                        {
                            (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                            ) &&
                            propertyOptions && propertyOptions.length > 0 && (
                                <Col md={2}>
                                    <Select
                                        value={selectedProperty}
                                        onChange={setSelectedProperty}
                                        isClearable
                                        options={propertyOptions}
                                        placeholder="Propriété"
                                        isDisabled={!!selectedVehicle} // Désactiver si un véhicule est sélectionné
                                    />
                                </Col>)
                        }

                        <Col md={2}>
                            <Form.Group>
                                <Form.Control
                                    style={{ height: "40px" }}
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Control
                                    style={{ height: "40px" }}
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </Form.Group>
                        </Col>


                        <Col md={2}>
                            {
                                // filteredData && filteredData.length > 0 &&
                                <Button onClick={handleSearchFilter} className="mt-3" size="sm"> <MdSearch /> </Button>
                            }
                            {
                                (
                                    selectedVehicle || selectedProperty || startDate || endDate
                                ) && (
                                    <Button variant="danger" size="sm" className="m-1 text-white" onClick={callFunctionElements}>
                                        <MdDeleteOutline /> Effacer
                                    </Button>
                                )
                            }
                        </Col>
                    </Row>

                    {/* Message si aucune réservation */}
                    {filteredData.length === 0 ? (
                        <p className="text-center">Aucune réservation disponible.</p>
                    ) : (
                        <>
                            {/* Tableau des réservations */}
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>

                                        <th>Image</th>
                                        <th style={{ minWidth: "200px" }}>N°</th>
                                        <th style={{ minWidth: "200px" }}>Patrimoine</th>
                                        <th style={{ minWidth: "200px" }}>Client</th>
                                        <th style={{ minWidth: "200px" }}>Moyen de paiement</th>
                                        <th style={{ minWidth: "200px" }}>Debut</th>
                                        <th style={{ minWidth: "200px" }}>Fin</th>
                                        <th>Prix</th>
                                        {
                                            (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                                            ) && (
                                                <th>Payé</th>
                                            )
                                        }
                                        <th>Statut</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData.map((reservation, index) => (
                                        <tr key={reservation._id}>
                                            <td>
                                                {
                                                    reservation.vehicle?.images?.[0] ? (
                                                        <div style={{ width: "70px", height: "70px" }}>
                                                            <img
                                                                src={reservation.vehicle.images[0] || emptyImage}
                                                                alt="vehicle"
                                                                className="rounded-md d-block w-100"
                                                                style={{ width: "70px", height: "70px" }}
                                                            />
                                                        </div>
                                                    ) : reservation.property?.images?.[0] ? (
                                                        <div style={{ width: "70px", height: "70px" }}>
                                                            <img
                                                                src={reservation.property.images[0] || emptyImage}
                                                                alt="vehicle"
                                                                className="rounded-md d-block w-100"
                                                                style={{ width: "70px", height: "70px" }}
                                                            />
                                                        </div>
                                                    ) : <img src={emptyImage}
                                                        alt="vehicle"
                                                        className="rounded-md d-block w-100"
                                                        style={{ width: "80px", height: "80px" }} />
                                                }
                                            </td>
                                            <td><span onClick={() => {
                                                if (reservation && reservation.vehicle) {
                                                    dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: reservation.vehicle });
                                                    dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                                                    navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${reservation._id}`)
                                                } else {
                                                    dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                                                    dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: reservation.property });
                                                    navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${reservation._id}`)
                                                }
                                            }}
                                                style={{ cursor: "pointer" }}
                                                className="text-decoration-underline">{reservation?.ordre}</span></td>

                                            <td>
                                                {reservation.vehicle?.brand || reservation.property?.name || reservation.property?.address || ""}
                                            </td>
                                            <td>{reservation.firstname} {reservation.lastname}</td>
                                            <td >
                                                {reservation.paymentMethod === "cash" && (<div className="d-flex flex flex-row"><FaMoneyBillWave size={24} /> <span> Espèces </span> </div>)}
                                                {reservation.paymentMethod === "bank" && (<div className="d-flex flex flex-row"><FaUniversity size={24} /> <span> Virement bancaire </span> </div>)}
                                                {reservation.paymentMethod === "card" && (<div className="d-flex flex flex-row"><FaCreditCard size={24} /> <span> Carte de crédit </span> </div>)}
                                            </td>
                                            <td>{formatDateView(reservation?.startDate)} - {moment(reservation.startDate).format("HH:mm")}</td>
                                            <td>{formatDateView(reservation?.endDate)} - {moment(reservation.endDate).format("HH:mm")}</td>
                                            <td>{Number(reservation.totalPrice).toFixed(2) || 0}</td>
                                            {
                                                (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                                                ) && (
                                                    <td>{Number(reservation.totalPriceReal).toFixed(2) || 0}</td>
                                                )}
                                            <td>{renderStatusBadge(reservation.status)}</td>
                                            <td>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={() => {
                                                        if (reservation && reservation.vehicle) {
                                                            dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: reservation.vehicle });
                                                            dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                                                            navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${reservation._id}`)
                                                        } else {
                                                            dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                                                            dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: reservation.property });
                                                            navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${reservation._id}`)
                                                        }
                                                    }}
                                                >
                                                    Détails
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {/* Pagination */}
                            {renderPagination()}
                        </>
                    )}
                </Card.Body>
            </Card>



            <Modal show={showLoading} size='sm' onShow={handleShowLoading} onHide={handleCloseLoading} centered style={{ background: "transparent" }}>
                <Modal.Body>
                    <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <div><Spinner variant='primary' /></div>
                    </div>
                </Modal.Body>
            </Modal>


        </div>
    );
};

export default RequestReservationPage;
